
































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Audio from '@/components/Audio.vue'
import { selectTagPosition, getNextTagPosition, getPrevTagPosition } from '@/utils/tag-utils'
import AdoriTag from '@/components/AdoriTag.vue'
import Analytics from '@/utils/analytics'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'
import Controls from '@/components/Controls.vue'
import Loading from '@/components/Loading.vue'
import Confirmation from '@/components/Confirmation.vue'
import Events from '@/utils/events' // eslint-disable-line
import axios from 'axios'
import ERRORS from '@/utils/errorMessages'
import Playlist from '@/components/Playlist.vue'
import Share from '@/components/Share.vue'
import MiniPlayer from '@/components/MiniPlayer.vue'
import DfpSlot from '@/components/DfpSlot.vue'
import TagBackground from '@/components/TagBackground.vue'
import BackgroundLayer from '@/components/BackgroundLayer.vue'

import {
  getImageUrl,
  useAuthToken,
  useNetworkId,
  getEpisodeMetadata,
  getAllTagPositions,
  getAudioUrl,
} from '@/utils/fetcher'
import get from 'lodash.get'
import { Getter } from 'vuex-class'

const legacyLandscape = () => import('@/styles/Player/Legacylandscape.css')
const landscape = () => import('@/styles/Player/Landscape.css')

@Component({
  components: {
    Audio,
    AdoriTag,
    Controls,
    Loading,
    Confirmation,
    Playlist,
    Share,
    MiniPlayer,
    DfpSlot,
    TagBackground,
    BackgroundLayer,
  },
  filters: {
    formatTime(value) {
      if (value === null) {
        return '--:--'
      }
      let intValue = Math.round(value)
      let minutes = Math.floor(intValue / 60)
      let seconds = Math.floor(intValue % 60)
      let minString = minutes.toString().padStart(2, '0')
      let secString = seconds.toString().padStart(2, '0')
      return `${minString}:${secString}`
    },
    millisToSec(value) {
      return value === null ? null : value / 1000
    },
  },
})
export default class AdoriPlayer extends Vue {
  @Getter episode!: any
  @Getter audio!: any
  @Getter srcAdded!: boolean
  @Getter isBookmarkClip!: any
  @Getter duration!: any
  @Getter currentTime!: any
  @Getter playbackRate!: any
  @Getter isFirstTimePlay!: any
  @Getter playing!: boolean

  show: any = null
  showRateChange = false
  showShare = false
  // tagPositions: [],
  showEpisodes: any = null
  titleOverflow = false
  displayEpisodeDesc = false
  displayShowDesc = false
  displayPlaylist = false
  showAdvancedButtons = false
  seekBarTime = 0
  userId = ''
  intervalPoll: any = null
  allEvents = []
  previousOffset = 0
  firstTimePlay = true
  newCurrentTagPosition: any = null
  isPreview = false
  isLoading = false
  showAnimation = false
  advancedControls = ''
  isInvalidAudio = false
  showContinueAudioPromt = false
  expPoll: any = null
  episodeDescLimit = 150
  autoplay = false
  controls = false
  seekBar = true
  full = false
  titleBar = true
  audiogram: any = null
  isProtected: boolean = false

  created() {
    this.$route.name === 'landscape' ? legacyLandscape() : landscape()
  }

  async mounted() {
    this.isLoading = true
    await this.setupPlayer()
    this.autoplay = get(this.$route.query, 'autoplay', 0) === '1'
    this.controls = get(this.$route.query, 'controls', '1') === '1'
    this.titleBar = get(this.$route.query, 'titleBar', '1') === '1'
    this.seekBar = get(this.$route.query, 'seekBar', 0) === '1'
    this.full = get(this.$route.query, 'full') === '1'
    // this.isLoading = false
    this.$route.name === 'landscape' && (this.titleBar = false)
  }
  beforeDestroy() {
    Events.postOnListened(this.episode.uid, this.currentTime, this.duration)
  }

  async setupPlayer() {
    this.isLoading = true
    const episodeUid = this.$route.params.episodeid
    const audioUrl = this.$route.query.url
    const token = this.$route.query.token
    const networkId = this.$route.query.network_id
    const showUid = this.$route.query.show_uid

    if (episodeUid || audioUrl || (token && networkId)) {
      if (episodeUid && token && networkId) {
        await this.handlePrivateEpisodeUid(episodeUid, token, networkId)
      } else if (episodeUid && audioUrl && showUid) {
        await this.handleBrandedEpisodeUrl(audioUrl, episodeUid, showUid)
      } else if (episodeUid) {
        await this.handleEpisodeUid(episodeUid)
      } else await this.handleEpisodeUrl(audioUrl)
    } else {
      this.isLoading = false
      this.isInvalidAudio = true
      console.log(ERRORS.episode)
    }
    // this.isLoading = false
  }

  async handlePrivateEpisodeUid(uid, token, networkId) {
    useAuthToken(token)
    useNetworkId(networkId)
    try {
      localStorage.setItem('id_token', token)
      const { data } = await AdoriServiceV6.fetchAudiogramOnTrack(networkId, uid)
      if (data.length) {
        const audiogramId = data[0].audiogramId
        const res = await AdoriServiceV6.fetchAudiogramById(networkId, audiogramId)
        this.audiogram = res.data
      }
    } catch (error) {
      console.log(ERRORS.audiogram)
    }
    try {
      const metaData = (await getEpisodeMetadata(uid)).data
      this.$store.dispatch('setEpisode', metaData)
      this.$store.dispatch('setDuration', metaData.durationMillis && metaData.durationMillis / 1000.0)
      if (this.episode.audioUrl) {
        this.$store.dispatch('setAudioSrc', this.episode.audioUrl)
      } else {
        this.$store.dispatch('setAudioSrc', getAudioUrl(this.episode.uid))
      }
    } catch (error) {
      console.log(ERRORS.episode)
      this.isLoading = false
      this.isInvalidAudio = true
    }
    try {
      const tags = (await getAllTagPositions(uid)).data
      this.$store.dispatch('setExperiences', tags)
    } catch (error) {
      // this.isLoading = false
      console.log(ERRORS.experiences)
    }
  }

  async handleEpisodeUid(uid) {
    try {
      const metaData = (await AdoriService.fetchEpisodeDetails(uid)).data
      const configUrl = 'https://adori.github.io/id.json'
      const response = await fetch(configUrl)
      const res: any = await response.json()

      if (uid === 'Iq9egrhKqWL2JjeA') {
        const filteredResult = res.users.find((e: any) => e.adori_id === 1906)
        console.log(filteredResult)
        this.isProtected = true
      } else {
        this.isProtected = false
      }

      this.$store.dispatch('setEpisode', metaData)
      this.$store.dispatch('setDuration', metaData.durationMillis && metaData.durationMillis / 1000.0)
      this.$store.dispatch('setAudioSrc', this.episode.audioUrl)
      this.handleEpisodeUrl(this.episode.audioUrl)
    } catch (error) {
      console.log(ERRORS.episode)
      this.isInvalidAudio = true
      this.isLoading = false
    }
  }

  async handleBrandedEpisodeUrl(url, uid, showuid) {
    try {
      const metaData = (await AdoriService.fetchEpisodeDetails(uid, showuid)).data

      if (metaData) {
        this.$store.dispatch('setEpisode', metaData)
        this.$store.dispatch('setDuration', metaData.durationMillis && metaData.durationMillis / 1000.0)
      }
      this.$store.dispatch('setAudioSrc', url)
      this.handleEpisodeUrl(url)
    } catch (error) {
      console.log(ERRORS.episode)
      this.isInvalidAudio = true
      this.isLoading = false
    }
  }

  async handleEpisodeUrl(url) {
    this.$store.dispatch('setAudioSrc', url)
    this.isLoading = false

    let redirectedUrl = ''

    try {
      const cancelRedirect = axios.CancelToken.source()
      await axios.get(url, {
        onDownloadProgress: (progres) => {
          const currentTarget = progres.target
          if (currentTarget.readyState >= 2) {
            redirectedUrl = currentTarget.responseURL
            cancelRedirect.cancel()
          }
        },
        cancelToken: cancelRedirect.token,
      })
    } catch (error) {
      console.log(ERRORS.requestCancel)
    }

    try {
      this.$store.dispatch('setPlayerPermission', {
        key: 'allowOtherEpisodes',
        value: false,
      })
      const self = this
      this.expPoll = setInterval(
        (function poll() {
          if (redirectedUrl) {
            self.handleExpPoll(redirectedUrl)
          } else {
            clearInterval(self.expPoll)
          }
          return poll
        })(),
        10000
      )
    } catch (error) {
      console.log(ERRORS.redirectUrl)
    }

    // this.isLoading = false
  }

  async handleExpPoll(url, setEpi = true) {
    try {
      const res: any = await AdoriService.fetchDecodedExperiences(url)
      if (res && res.status === 200) {
        clearInterval(this.expPoll)

        if (!setEpi) {
          this.$store.dispatch('setEpisode', res.data.episode)
          this.$store.dispatch(
            'setDuration',
            res.data.episode.durationMillis && res.data.episode.durationMillis / 1000.0
          )
        }
        this.$store.dispatch('setDecodedExperiences', res.data.experiences)
      }
    } catch (error) {
      clearInterval(this.expPoll)
      this.isInvalidAudio = true
      console.log(ERRORS.decode)
    }
  }

  handleAudioLoad() {
    this.isLoading = false
    if (this.startTime > 0 && !this.isBookmarkClip) this.handleAudioClipPause()
  }

  handleAudioClipPause() {
    this.showContinueAudioPromt = true
    setTimeout(() => {
      this.showContinueAudioPromt = false
    }, 4000)
  }
  handleAudioContinue(res) {
    if (!res) this.audio.currentTime = 0
    this.audio.play()
    this.showContinueAudioPromt = false
  }
  changePlaybackRate(rate) {
    this.$store.dispatch('changePlaybackRate', rate)
  }
  toggleShowRateChange() {
    this.displayEpisodeDesc = false
    this.showShare = false
    this.displayPlaylist = false
    this.displayShowDesc = false
    this.showRateChange = !this.showRateChange
  }
  toggleShowShare() {
    this.displayEpisodeDesc = false
    this.showRateChange = false
    this.displayPlaylist = false
    this.displayShowDesc = false
    this.showShare = !this.showShare
  }
  toggleDisplayEpisodeDesc() {
    this.showRateChange = false
    this.showShare = false
    this.displayPlaylist = false
    this.displayShowDesc = false
    this.displayEpisodeDesc = !this.displayEpisodeDesc
  }
  toggleDisplayShowDesc() {
    this.showRateChange = false
    this.showShare = false
    this.displayPlaylist = false
    this.displayEpisodeDesc = false
    this.displayShowDesc = !this.displayShowDesc
  }
  toggleDisplayPlaylist() {
    this.showRateChange = false
    this.showShare = false
    this.displayEpisodeDesc = false
    this.displayShowDesc = false
    this.displayPlaylist = !this.displayPlaylist
  }
  hasTitleOverflown() {
    let episodeTitle: any = this.$refs.episodeTitle

    if (episodeTitle && episodeTitle.scrollWidth > episodeTitle.offsetWidth) {
      this.titleOverflow = true
    } else {
      this.titleOverflow = false
    }
    return episodeTitle
  }
  nextTag() {
    let tagPosition = getNextTagPosition(this.tagPositions, this.currentTime)
    if (this.firstTimePlay) {
      // this.intervalPoll = setInterval(this.postOnInterval, 30000)
      this.firstTimePlay = false
    }
    if (tagPosition !== undefined) {
      let seekTo = tagPosition.offsetMillis / 1000
      // if (seekTo === this.seekTo) {
      //   seekTo = seekTo + 0.01;
      // }
      this.$store.dispatch('seekAudio', seekTo)
    }
  }
  prevTag() {
    let tagPosition = getPrevTagPosition(this.tagPositions, this.currentTime)
    if (tagPosition !== undefined) {
      let seekTo = tagPosition.offsetMillis / 1000
      // if (seekTo === this.seekTo) {
      //   seekTo = seekTo + 0.01;
      // }
      this.$store.dispatch('seekAudio', seekTo)
    }
  }
  swipeLeftHandler(event) {
    if (this.hasNextTag) {
      this.nextTag()
    }
  }
  swipeRightHandler(event) {
    if (this.hasPrevTag) {
      this.prevTag()
    }
  }
  toReadableDate(time) {
    const date = new Date(time).toString()
    return date.slice(date.indexOf(' ')).slice(0, date.indexOf(':') - 5)
  }
  handleTagAction(action) {
    Analytics.postTagInteracted(this.currentTagPosition)
  }
  async handleOptionalTagAction(option) {
    await AdoriService.submitPoll(this.currentTagPosition && this.currentTagPosition.id, option)
    await Analytics.postTagInteracted(this.currentTagPosition)
    // await this.handleExperiences();
  }
  get progressPercentage() {
    if (this.currentTime === null || this.duration === null) {
      return 0
    }
    return (this.currentTime / this.duration) * 100
  }
  get episodeImage() {
    return this.episode.imageUrl ? this.episode.imageUrl : this.episode.image ? this.episode.image.urls.regular : ''
  }

  get audiogramSrc() {
    return this.audiogram && this.audiogram.image ? this.audiogram.image.originalUrl : ''
  }
  get tagImage() {
    return this.currentTag.imageInfo
      ? this.currentTag.imageInfo.url
      : this.currentTag.image
      ? this.currentTag.image.urls.regular
      : ''
  }
  get showImage() {
    if ('imageUrl' in this.show) {
      return this.show.imageUrl
    } else {
      return getImageUrl(this.show.imageId)
    }
  }
  get currentTagPosition(): any {
    if (this.currentTime !== null) {
      // eslint-disable-next-line
      this.newCurrentTagPosition = selectTagPosition(this.tagPositions, this.currentTime)
      return selectTagPosition(this.tagPositions, this.currentTime)
    }
    return null
  }

  get currentTagDuration() {
    return (
      this.currentTime <
      (this.currentTagPosition &&
        (this.currentTagPosition.durationMillis + this.currentTagPosition.offsetMillis) / 1000)
    )
  }
  get currentTag() {
    let selectedTagPosition: any = this.currentTagPosition
    if (selectedTagPosition !== null && this.tagsById(selectedTagPosition.tag.id)) {
      return this.tagsById(selectedTagPosition.tag.id)
    }
    return null
  }
  get tagType() {
    return this.currentTag.actions
  }
  get rawEpisodeDescription() {
    if (!this.episode.description) return ''
    const description = this.episode.description
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/gi, '')
    if (description.length < this.episodeDescLimit) return description
    return description.slice(0, this.episodeDescLimit) + '...'
  }
  get episodeDescription() {
    return this.episode.description
  }
  get showDescription() {
    var span = document.createElement('span')
    span.innerHTML = this.show.description
    return span.textContent || span.innerText
  }
  get hasNextTag() {
    let tagPosition = getNextTagPosition(this.tagPositions, this.currentTime)
    if (tagPosition !== undefined) {
      return true
    } else {
      return false
    }
  }
  get hasPrevTag() {
    let tagPosition = getPrevTagPosition(this.tagPositions, this.currentTime)
    if (tagPosition !== undefined) {
      return true
    } else {
      return false
    }
  }
  get showNextTag() {
    return this.hasNextTag
  }
  get showPrevTag() {
    return this.hasPrevTag
  }
  get startTime() {
    return this.$store.getters.startTime
  }
  get stopTime() {
    return this.$store.getters.stopTime
  }
  get tagsById() {
    return this.$store.getters.tagsById
  }
  get tagPositions() {
    return this.$store.getters.experiences
  }
  get isOtherEpisodesVisible() {
    return this.$store.getters.isOtherEpisodesVisible
  }
  get getUniqueTagDiv() {
    return this.episode.name + '_' + Math.random().toString(36).substr(2, 5)
  }

  @Watch('seekBarTime')
  seekBarTimeWatcher(newVal, oldVal) {
    // this.postSeekEvent(newVal, oldVal, this.duration, this.currentTime, this.episode)
    let seekTime = (newVal * this.duration) / 100
    if (Math.abs(seekTime - this.currentTime) > 1) {
      this.$store.dispatch('setCurrentTime', seekTime)
      this.$store.dispatch('seekAudio', seekTime)
      this.previousOffset = Math.floor((newVal * this.duration) / 100)
    }
  }

  @Watch('progressPercentage')
  progressPercentageWatcher(newVal, oldVal) {
    this.seekBarTime = newVal
  }

  @Watch('newCurrentTagPosition')
  newCurrentTagPositionWatcher(newVal, oldVal) {
    if (newVal) Analytics.postTagServed(newVal)
    if (this.isFirstTimePlay) {
      Analytics.startAnalyticsPoll()
      this.$store.dispatch('setIsFirstTimePlay', false)
    }
    Events.postCurrentTag(newVal && newVal.tag)
  }

  @Watch('playing')
  handlePlaying() {
    if (this.playing && this.srcAdded) {
      this.audio
        .play()
        .then((_) => {
          if (this.isFirstTimePlay) {
            Analytics.startAnalyticsPoll()
            this.$store.dispatch('setIsFirstTimePlay', false)
          }
        })
        .catch((reason) => {
          console.error('Cannot play audio: ', reason)
          console.log(reason.message)
        })
    } else {
      this.audio.pause()
    }
  }

  @Watch('$route')
  $routeWatcher(to, from) {
    this.setupPlayer()
    if (from && from.params && from.params.episodeid) {
      Events.postOnListened(from.params.episodeid, this.currentTime, this.duration)
    }
  }

  @Watch('isInvalidAudio')
  isInvalidAudioWatcher(newVal) {
    if (newVal) {
      Events.postOnError(ERRORS.episode)
    }
  }
}
