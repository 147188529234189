







import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
const landscape = () => import('@/styles/AdoriTag/Landscape.css')

@Component
export default class TagBackground extends Vue {
  @Prop(Object) currentTag!: any
  @Prop(String) src!: string

  @Getter playing!: boolean

  @Watch('playing')
  handle() {
    if (this.videoOrImage) {
      const video: any = this.$refs.video
      if (this.playing) {
        video.play()
      }
      if (!this.playing) {
        video.pause()
      }
    }
  }

  created() {
    this.$route.name === 'landscape' && landscape()
  }

  get videoSrc() {
    return this.currentTag && this.currentTag.video && this.currentTag.video.originalUrl
  }
  get videoOrImage() {
    if (this.currentTag.video && this.currentTag.video.id) {
      return true
    } else {
      return false
    }
  }
}
